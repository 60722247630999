import myEpicNft from "../../utils/MyEpicNFT.json";
import callApi from "../../helpers/conectorApi.js";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import { ethers } from "ethers";
import Loader from "../Loader/Loader.js";

import "./Admin.css";

const CONTRACT_ADDRESS = "0xfec50dae05902f4a1c303da8fb7477f7dea751d5";
function compareNumbers(a, b) {
  return a - b;
}

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
export default function Admin() {
  const chainNeeded = "0x89";
  const [regs, setregs] = useState();
  const [finalRegs, setfinalRegs] = useState([]);
  const [statistics, setstatistics] = useState({ wallets: "-", tokens: "-" });
  const [rerror, seterror] = useState();
  const [loading, setloading] = useState(true);
  const [loadingRegs, setloadingRegs] = useState(true);
  const [validNetwork, setvalidNetwork] = useState(false);
  const [currentAccount, setCurrentAccount] = useState("");
  useEffect(() => {
    checkIfWalletIsConnected();
    return () => {};
  }, []);
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      let wrapper = document.createElement("div");
      ReactDOM.render(
        <p>
          Para que puedas conectar tu billetera es necesario que te encuentres
          en la red de Polygon. En esta página encontrarás todos los videos para
          entender cómo funciona el proceso, paso a paso. Haz clic donde dice
          'video tutoriales' .Agregar el boton 'Video tutoriales' que tire aca:{" "}
          <a href="https://www.platzees.io/conectar">
            https://www.platzees.io/conectar
          </a>
        </p>,
        wrapper
      );
      let el = wrapper.firstChild;

      swal({
        title: "¡Estás en la red equivocada!",
        content: el,
        icon: "error"
      });
      return;
    } else {
    }
    let chainId = await ethereum.request({ method: "eth_chainId" });

    if (chainId !== chainNeeded) {
      setvalidNetwork(false);
      let wrapper = document.createElement("div");
      ReactDOM.render(
        <p>
          Para que puedas conectar tu billetera es necesario que te encuentres
          en la red de Polygon. En esta página encontrarás todos los videos para
          entender cómo funciona el proceso, paso a paso. Haz clic donde dice
          'video tutoriales' .Agregar el boton 'Video tutoriales' que tire aca:{" "}
          <a href="https://www.platzees.io/conectar">
            https://www.platzees.io/conectar
          </a>
        </p>,
        wrapper
      );
      let el = wrapper.firstChild;

      swal({
        title: "¡Estás en la red equivocada!",
        content: el,
        icon: "error"
      });
      return;
    } else {
      setvalidNetwork(true);
    }
    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts.length !== 0) {
      const account = accounts[0];

      setCurrentAccount(account);
      setloading(false);
      getById();

      // Setup listener! This is for the case where a user comes to our site
      // and ALREADY had their wallet connected + authorized.
      setupEventListener();
    } else {
      setloading(false);
    }
    //else {
    //  swal("Oops!", "No authorized account found", "error");
    //}

    //setUriContract();
  };

  const getById = async () => {
    try {
      if (!regs) {
        let response = await callApi("/regdividend/All", {}, false);
        if (response.data) {
          if (response.data.count > 0) {
            setregs(response.data.rows);
          }
        }
      }
    } catch (error) {
      setloadingRegs(false);
      seterror("Error en obtener la informacion.");
    }
  };

  useEffect(() => {
    if (regs) {
      updateArray(regs)
        .then((updatedArray) => {
          setloadingRegs(false);
          var groupedArray = groupByQuantity(updatedArray);
          groupedArray.sort((a, b) => b.quantity - a.quantity);
          setstatistics(countTokens(groupedArray));
          setfinalRegs(groupedArray);
          // Use updatedArray as needed
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    return () => {};
  }, [regs]);

  // Setup our listener.
  const setupEventListener = async () => {
    // Most of this looks the same as our function askContractToMintNft
    try {
      const { ethereum } = window;

      if (ethereum) {
        // Same stuff again
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(
          CONTRACT_ADDRESS,
          myEpicNft.abi,
          signer
        );
      } else {
        swal("Oops!", "Ethereum object doesn't exist!", "error");
      }
    } catch (error) {}
  };

  const getTokens = async (wallet) => {
    try {
      const { ethereum } = window;
      if (!ethereum) {
        swal(
          "Oops!",
          "Utiliza un navegador que tenga instalado metamask.",
          "error"
        );
        return;
      }
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        myEpicNft.abi,
        signer
      );
      let nftTxn = await connectedContract.tokenByOwner(wallet);
      let tokenIds = [];
      nftTxn.map((item, index) => {
        tokenIds.push(parseInt(item._hex, 16));
      });
      tokenIds.sort(compareNumbers);
      await sleep(1000);

      return tokenIds;
    } catch (error) {}
  };
  const groupByQuantity = (array) => {
    return array.reduce((groups, item) => {
      const { quantity, ...rest } = item;
      const group = groups.find((group) => group.quantity === quantity);
      if (group) {
        group.items.push(rest);
      } else {
        groups.push({ quantity, items: [rest] });
      }
      return groups;
    }, []);
  };

  function countTokens(data) {
    let totalCount = 0;
    let totalQuantity = 0;
    data.forEach((group) => {
      group.items.forEach((item) => {
        totalCount += item.tokenlist.length;
        totalQuantity += 1;
      });
    });
    return { tokens: totalCount, wallets: totalQuantity };
  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        swal(
          "Oops!",
          "Utiliza un navegador que tenga instalado metamask.",
          "error"
        );
        return;
      }

      if (!validNetwork) {
        let wrapper = document.createElement("div");
        ReactDOM.render(
          <p>
            Para que puedas conectar tu billetera es necesario que te encuentres
            en la red de Polygon. En esta página encontrarás todos los videos
            para entender cómo funciona el proceso, paso a paso. Haz clic donde
            dice 'video tutoriales' .Agregar el boton 'Video tutoriales' que
            tire aca:{" "}
            <a href="https://www.platzees.io/conectar">
              https://www.platzees.io/conectar
            </a>
          </p>,
          wrapper
        );
        let el = wrapper.firstChild;

        swal({
          title: "¡Estás en la red equivocada!",
          content: el,
          icon: "error"
        });
        return;
      }
      const accounts = await ethereum.request({
        method: "eth_requestAccounts"
      });

      setCurrentAccount(accounts[0]);

      // Setup listener! This is for the case where a user comes to our site
      // and connected their wallet for the first time.
      setupEventListener();
      getById();
      setloading(false);
    } catch (error) {}
  };
  const renderNotConnectedContainer = () => (
    <button
      onClick={connectWallet}
      className="cta-button connect-wallet-button "
    >
      Conecta tu wallet
    </button>
  );

  const updateArray = async (originalArray) => {
    try {
      const updatedArray = await Promise.all(
        originalArray.map(async (item) => {
          const tokenlist = await getTokens(item.wallet);
          await sleep(1000);
          // Introduce delay before making the request
          console.log(tokenlist);
          return {
            ...item,
            tokenlist,
            quantity: tokenlist.length
          };
        })
      );
      return updatedArray;
    } catch (error) {
      console.error("Error updating array:", error);
      return [];
    }
  };
  return (
    <div className="ModelPlatzeeContainer">
      {loading && (
        <>
          <Loader></Loader>
          <div>
            <div className="App-tb-container">
              <div className="App-tb">
                <div className="App-tb-paragraph">
                  <br></br>
                  En este momento estamos identificando la información de tu
                  billetera digital de Metamask. Éste proceso podría tardar un
                  par de minutos. Si ha pasado más tiempo que eso, por favor
                  actualiza esta página e inicia el proceso de nuevo.
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!loading && currentAccount === "" && (
        <>
          <div className="App-connect-mint">
            <div className="App-tb-container">
              <div className="App-tb">
                <div className="App-tb-title">¡Bienvenido Platzee Admin!</div>
                <div className="App-tb-paragraph">
                  Esperamos que te encuentres en excelente. Aca puedes ver el
                  resumen de las wallets registradas al dia de hoy.
                </div>
              </div>
            </div>
            {renderNotConnectedContainer()}
          </div>
        </>
      )}
      {loadingRegs ? (
        <Loader></Loader>
      ) : (
        finalRegs && (
          <>
            <div className="modelList">
              <div className="modeListStatistics">
                <div className="modelListItemStatistics">
                  <div>{statistics.wallets}</div>
                  <div>Wallets</div>
                </div>
                <div className="modelListItemStatistics">
                  <div>{statistics.tokens}</div>
                  <div>Tokens</div>
                </div>
                <div className="modelListItemStatistics">
                  <div>??</div>
                  <div>USDT</div>
                </div>
                <div className="modelListItemStatistics">
                  <div>0</div>
                  <div>Pagado</div>
                </div>
              </div>
            </div>
            {finalRegs.map((item, index) => {
              return (
                <div className="modelList" key={`quantity${index}`}>
                  <div className="modeListHeader">
                    {" "}
                    {item.quantity} NFT Total:{" "}
                    {item.quantity * item.items.length}
                  </div>
                  <div className="modeListWallets">
                    {item.items.map((it, indexit) => {
                      return (
                        <div
                          className="modelListItem"
                          key={`wallets${indexit}`}
                        >
                          {it.wallet}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )
      )}
    </div>
  );
}
